import { QueryClient, UseQueryOptions } from '@tanstack/react-query';

const defaultOptions: { queries: UseQueryOptions } = {
  queries: {
    retry: 1,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  },
};

export const queryClient = new QueryClient({ defaultOptions });
