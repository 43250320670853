import numeral from 'numeral';

import {
  formatCurrency,
  validateNumber,
  validateCommaNumber,
  isRationalNumber,
  isDecimalNumber,
  isDashNumber,
  isNumeric,
} from 'features/utils';

/**
 * 날짜 입력값을 파싱하여 형식에 맞는 문자열을 반환합니다.
 * @param {string} preValue - 이전 값입니다.
 * @param {string} value - 현재 값입니다.
 * @returns {string} 파싱된 형식에 맞는 문자열을 반환합니다.
 * parseDateInput('', '20230101'); // '2023-01-01'
 */
export const parseDateInput = (preValue: string, value: string): string => {
  if (!isDashNumber(value)) {
    return preValue;
  }
  if (value.length > 10) {
    return value.slice(0, 10);
  }
  if (value.length === 5 && /^\d{5}$/.test(value)) {
    return value.slice(0, 4) + '-' + value.slice(4);
  }
  if (value.length === 8 && /^\d{4}-\d{3}$/.test(value)) {
    return value.slice(0, 7) + '-' + value.slice(7);
  }
  if (
    (value.length <= 4 && !validateNumber(value)) ||
    (value.length > 5 &&
      value.length <= 8 &&
      !validateNumber(value.slice(5, 7))) ||
    (value.length > 8 && !validateNumber(value.slice(8)))
  ) {
    return preValue;
  }

  return value;
};

// TODO: 들이는 시간 대비 의미가 없는 거 같아서 parser 파일의 유틸들은 리팩토링 보류
export const parseTimeInput = (preValue: string, value: string) => {
  if (value.length <= 2 && !validateNumber(value)) {
    return preValue;
  }
  if (value.length > 5) {
    return value.slice(0, 5);
  }
  if (value.length >= 3 && /^\d*$/.test(value.slice(2))) {
    return value.slice(0, 2) + ':' + value.slice(2);
  }

  if (value.length > 3 && !validateNumber(value.slice(3))) {
    return preValue;
  }

  return value;
};

export const parseCurrencyInput = (prevValue: string, value: string) => {
  if (value.length < 4) {
    return validateNumber(value) ? value : prevValue;
  } else {
    return validateCommaNumber(value) ? formatCurrency(value) : prevValue;
  }
};

export const parseIntegerInput = (
  preValue: string,
  value: string,
  positiveOnly: boolean,
) => {
  if (!validateNumber(value)) {
    return preValue;
  }
  if (positiveOnly && Number(value) < 0) {
    return preValue;
  }
  return value;
};

export const parseDecimalInput = (
  preValue: string,
  value: string,
  digit: number,
) => {
  const isPeriod = value.includes('.');
  const startsZero = preValue[0] === '0';
  const splitPeriod = value.split('.');

  if (value.length === 1 && !validateNumber(value)) {
    return preValue;
  }
  if (!isDecimalNumber(value)) {
    return preValue;
  }

  if (isPeriod && splitPeriod.length > 2) {
    return preValue;
  }
  if (isPeriod && splitPeriod[1].length > digit) {
    return preValue;
  }
  if (startsZero && value[1] !== '.' && preValue.length < value.length) {
    return preValue;
  }
  if (Number(splitPeriod[0]) >= 100) {
    return '100';
  }

  return value;
};

export const parseNumberFormatString = (value: string) => {
  const removeComma = value.replaceAll(',', '');
  return Number(removeComma);
};

export const checkMinMaxValue = (
  prevValue: string | undefined,
  value: string | undefined,
  minValue?: number,
  maxValue?: number,
) => {
  if (value === undefined || !isNumeric(value)) {
    return value;
  }
  const numValue = numeral(value).value();
  if (minValue !== undefined && numValue !== null && numValue < minValue) {
    return prevValue;
  }
  if (maxValue !== undefined && numValue !== null && numValue > maxValue) {
    return prevValue;
  }
  return value;
};

export const checkUnderMaxDecimalPoint = (
  value: string | undefined,
  maxDecimal: number,
) => {
  if (value === undefined) {
    return true;
  }
  const splitPeriod = value.split('.');
  if (splitPeriod.length === 2) {
    const afterComma = splitPeriod[1];
    if (afterComma.length > maxDecimal) {
      return false;
    }
  }
  return true;
};

export const parseRationalNumber = (
  prevValue: string | undefined,
  newValue: string | undefined,
  isNegativeDisallowed?: boolean,
  isDecimalDisallowed?: boolean,
) => {
  if (newValue === undefined) {
    return newValue;
  }
  const isMinus = newValue[0] === '-';
  if (isNegativeDisallowed && isMinus) {
    return prevValue;
  }
  const isPeriod = newValue.includes('.');
  if (isDecimalDisallowed && isPeriod) {
    return prevValue;
  }
  if (!isRationalNumber(newValue)) {
    return prevValue;
  }
  if (isMinus && !isPeriod) {
    if (newValue.length === 1) {
      return newValue;
    } else {
      const withoutMinus = newValue.substring(1);
      if (withoutMinus !== undefined) {
        return `-${formatCurrency(withoutMinus)}`;
      }
    }
  }
  if (validateCommaNumber(newValue)) {
    return formatCurrency(newValue);
  }

  if (isPeriod && prevValue !== '-') {
    const [beforeComma, afterComma] = newValue.split('.');

    const formattedValue =
      afterComma === undefined ? beforeComma : `${beforeComma}.${afterComma}`;
    return formattedValue;
  }
  return prevValue;
};

export const addCommaToFloat = (value: string | undefined) => {
  if (value === undefined || value === '') {
    return value;
  }
  if (!value.includes('.')) {
    return `${value}.0`;
  } else {
    const zeroAddedValue = value.startsWith('.') ? `0${value}` : value;
    if (zeroAddedValue[zeroAddedValue.length - 1] === '.') {
      return `${zeroAddedValue}0`;
    } else {
      return zeroAddedValue;
    }
  }
};

export const parseIntWithCommas = (value: string) => {
  return parseInt(value.replaceAll(',', ''));
};
