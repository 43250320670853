/**
 * 입력된 알파벳의 다음 알파벳 문자를 반환합니다.
 * 입력이 유효한 단일 알파벳 문자가 아닌 경우 'A'를 반환합니다.
 * 입력이 'Z'인 경우 'A'를 반환합니다.
 * 입력이 'z'인 경우 'a'를 반환합니다.
 * 그 외의 단일 알파벳 문자는 다음 문자를 반환합니다.
 *
 * @param {string | undefined | null} alphabet - 단일 알파벳 문자.
 * @returns {string} 다음 알파벳 문자, 입력이 유효하지 않으면 'A'.
 */
export const getNextAlphabet = (alphabet: string | undefined | null) => {
  if (!alphabet || alphabet.length !== 1 || !/[a-zA-Z]/.test(alphabet)) {
    return 'A';
  }

  if (alphabet === 'Z') {
    return 'A';
  }

  if (alphabet === 'z') {
    return 'a';
  }

  return String.fromCharCode(alphabet.charCodeAt(0) + 1);
};
