import { PropsWithChildren } from 'react';

import { menuItems } from 'constants/';

import { Header } from 'components/Layouts';

import styles from 'components/Layouts/GNBLayout/GNBLayout.module.scss';

const GNBLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className={styles.root}>
      <Header items={menuItems} />
      <div className={styles.content}>{children}</div>
    </main>
  );
};

export default GNBLayout;
