import { addDays } from 'date-fns';

type SetCookieOptions = {
  expires?: Date;
  path?: string;
};

type SetCookieProps = {
  key: string;
  value: string;
  options?: SetCookieOptions;
};

/**
 * 웹 브라우저 쿠키 관련 동작을 수행
 */
export const cookie = {
  /**
   * 주어진 키에 해당하는 쿠키 getter
   * @param {string} key - 가져올 쿠키의 키
   * @returns {string | undefined} 주어진 키에 해당하는 쿠키 값이 있으면 해당 값을, 없으면 undefined를 반환
   */
  getCookie: (key: string): string | undefined => {
    const cookie = document.cookie
      .split(';')
      .find((cookie) => cookie.trim().startsWith(`${key}=`));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : undefined;
  },

  /**
   * 쿠키를 설정합니다.
   * @param {string} props.key - 쿠키의 키
   * @param {string} props.value - 쿠키의 값
   * @param {object} [props.options] - 쿠키 옵션
   * @param {Date} [props.options.expires] - 쿠키의 만료 일, 기본값은 현재 시간에서 하루 뒤
   * @param {string} [props.options.path] - 쿠키의 경로, 기본값은 '/'
   */
  setCookie: ({
    key,
    value,
    options = {
      expires: addDays(new Date(), 1),
      path: '/',
    },
  }: SetCookieProps) => {
    const { expires, path } = options;
    const cookieOptions = options
      ? { expires: expires?.toUTCString(), path }
      : {};
    document.cookie = `${key}=${encodeURIComponent(value)}; ${Object.entries(
      cookieOptions,
    )
      .map(([key, value]) => `${key}=${value}`)
      .join('; ')}`;
  },

  /**
   * 주어진 키에 해당하는 쿠키를 삭제
   * @param {string} key - 삭제할 쿠키의 키
   */
  removeCookie: (key: string) => {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  },
};
