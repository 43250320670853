import { Dispatch, SetStateAction, useState } from 'react';

interface ActiveState {
  activeIndex: number[];
  setActiveIndex: Dispatch<SetStateAction<number[]>>;
}

interface ToggleChildrenEffectProps {
  length: number;
  initialState?: number[];
  checkNextIsActive?: (index: number, state: ActiveState) => number[];
}

type ToggleChildrenEffect = ({
  length,
  initialState,
  checkNextIsActive,
}: ToggleChildrenEffectProps) => [
  number[],
  Dispatch<SetStateAction<number[]>>,
  Array<() => void>,
];

export const useChildrenToggle: ToggleChildrenEffect = ({
  length,
  initialState = [0],
  checkNextIsActive,
}): [number[], Dispatch<SetStateAction<number[]>>, Array<() => void>] => {
  const [activeIndex, setActiveIndex] = useState<number[]>(initialState);

  const callbacks = Array.from(Array(length).keys()).map((_, index) => {
    if (checkNextIsActive) {
      return () => {
        const nextActiveIndex = checkNextIsActive(index, {
          activeIndex,
          setActiveIndex,
        });
        setActiveIndex(nextActiveIndex);
      };
    } else {
      return () => {
        const nextActiveIndex = activeIndex.filter((value) => value !== index);
        if (nextActiveIndex.length === activeIndex.length) {
          nextActiveIndex.push(index);
        }
        setActiveIndex(nextActiveIndex);
      };
    }
  });

  return [activeIndex, setActiveIndex, callbacks];
};
