import { logicalOperators } from 'constants/';

/**
 * 주어진 값이 유효한 날짜 형식인지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 유효한 날짜 형식인 경우 true, 그렇지 않으면 false를 반환
 */
export const validateDate = (value: string) => {
  const DATE_REGEX = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  return DATE_REGEX.test(value);
};

/**
 * 주어진 값이 유효한 시간 형식인지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 유효한 시간 형식인 경우 true, 그렇지 않으면 false를 반환
 */
export const validateTime = (value: string): boolean => {
  const TIME_REGEX = /^(0[1-9]|1[0-9]|2[0-3]):([0-5])([0-9])$/;
  return TIME_REGEX.test(value);
};

/**
 * 주어진 문자열이 숫자로만 구성되어 있는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 숫자로만 구성되어 있으면 true, 그렇지 않으면 false를 반환
 */
export const validateNumber = (value: string): boolean => {
  const NUMBER_REGEX = /^\d*$/;
  return NUMBER_REGEX.test(value);
};

/**
 * 주어진 문자열이 숫자와 쉼표로만 구성되어 있는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 숫자와 쉼표로만 구성되어 있으면 true, 그렇지 않으면 false를 반환
 */
export const validateCommaNumber = (value: string): boolean => {
  const COMMA_NUMBER_REGEX = /^[\d,]*$/;
  return COMMA_NUMBER_REGEX.test(value);
};

/**
 * 주어진 문자열 값이 0으로 시작하지 않는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 문자열 값이 0으로 시작하지 않으면 true, 그렇지 않으면 false를 반환
 */
export const checkNotStartingWithZero = (value: string): boolean => {
  return value[0] !== '0';
};

/**
 * 주어진 문자열이 유효한 유리수 형식인지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 유효한 유리수 형식이면 true, 그렇지 않으면 false를 반환
 */
export const isRationalNumber = (value: string): boolean => {
  const RATIONAL_REGEX = /^[\d,.]*$/;
  if (value.length === 1 && value === '-') {
    return true;
  }

  if (value[0] === '-') {
    return RATIONAL_REGEX.test(value.substring(1));
  }

  return RATIONAL_REGEX.test(value);
};

/**
 * 주어진 문자열이 소수점을 포함한 숫자 형식인지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 소수점을 포함한 숫자 형식이면 true, 그렇지 않으면 false를 반환
 */
export const isDecimalNumber = (value: string): boolean => {
  const DECIMAL_NUMBER_REGEX = /^[\d.]*$/;
  return DECIMAL_NUMBER_REGEX.test(value);
};

/**
 * 주어진 문자열이 숫자 또는 하이픈으로만 구성되어 있는지 확인
 * @param {string} value - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 숫자 또는 하이픈으로만 구성되어 있으면 true, 그렇지 않으면 false를 반환
 */
export const isDashNumber = (value: string): boolean => {
  const DASH_NUMBER_REGEX = /^[\d-]*$/;
  return DASH_NUMBER_REGEX.test(value);
};

/**
 * 주어진 문자열이 연산자인지 확인
 * @param {string} kind - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 연산자이면 true, 그렇지 않으면 false를 반환
 */
export const getIsOperator = (kind: string): boolean =>
  logicalOperators.some((operator) => operator === kind);
