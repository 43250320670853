import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1099 });
  const isDesktop = useMediaQuery({ minWidth: 1100 });
  const isUnSupported = useMediaQuery({ maxWidth: 1099 });

  return { isMobile, isTablet, isDesktop, isUnSupported };
};
