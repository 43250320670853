import numeral from 'numeral';

/**
 * 주어진 값에 대한 통화 포맷을 적용
 * @param {T} s - 포맷을 적용할 값
 * @returns {string | undefined} 포맷이 적용된 통화 문자열을 반환, 값이 빈 문자열이 아닌 경우에만 포맷이 적용
 * @example
 * formatCurrency(1234567); // "1,234,567"
 */
export const formatCurrency = <T>(s: T): string | undefined => {
  if (s !== '') {
    return numeral(Math.floor(Number(s))).format('0,0');
  }
};

/**
 * 주어진 값에 대한 부호와 통화 포맷을 적용
 * @param {T} s - 포맷을 적용할 값
 * @returns {string} 부호와 포맷이 적용된 통화 문자열을 반환
 * @example
 * formatCurrencyWithSign(1234567); // "+1,234,567"
 */
export const formatCurrencyWithSign = <T>(s: T): string => {
  return numeral(s).format('+0,0');
};

/**
 * 주어진 값에 대한 부호와 포맷을 적용
 * @param {T} s - 포맷을 적용할 값
 * @param {boolean} [showDecimal=true] - 소수점을 표시할지 여부, 기본값은 true
 * @param {boolean} [currencyFormat=false] - 통화 형식으로 포맷을 적용할지 여부, 기본값은 false
 * @param {boolean} [forceDecimal=false] - 소수점 강제 표시 여부, 기본값은 false
 * @returns {string} 부호와 포맷이 적용된 문자열을 반환
 * @example
 * signedFormatter(1234.56); // "+1,234.56"
 */
export const signedFormatter = <T>(
  s: T,
  showDecimal = true,
  currencyFormat = false,
  forceDecimal = false,
): string => {
  const integerFormat = currencyFormat ? '0,0' : '0';
  return numeral(s).format(
    showDecimal
      ? forceDecimal
        ? `+${integerFormat}.00`
        : `+${integerFormat}.[00]`
      : `+${integerFormat}`,
  );
};

/**
 * 주어진 값에 대한 소수점 포맷을 적용
 * @param {T} s - 포맷을 적용할 값
 * @param {boolean} [forceDecimal=false] - 소수점 강제 표시 여부, 기본값은 false
 * @param {boolean} [currencyFormat=false] - 통화 형식으로 포맷을 적용할지 여부, 기본값은 false
 * @returns {string} 포맷이 적용된 문자열을 반환
 * @example
 * decimalFormatter(1234.56); // "1,234.56"
 */
export const decimalFormatter = <T>(
  s: T,
  forceDecimal = false,
  currencyFormat = false,
) => {
  const integerFormat = currencyFormat ? '0,0' : '0';
  return numeral(s).format(
    forceDecimal ? `${integerFormat}.00` : `${integerFormat}.[00]`,
  );
};

/**
 * 주어진 숫자를 지정된 소수 자릿수로 반올림
 * @param {number} n - 반올림할 숫자
 * @param {number} [pos=2] - 소수 자릿수, 기본값은 2
 * @returns {number} 반올림된 결과를 반환
 * @example
 * roundFloat(3.141592653589793, 2); // 3.14
 */
export const roundFloat = (n: number, pos = 2): number => {
  return Math.round(n * 10 ** pos) / 10 ** pos;
};

/**
 * 주어진 숫자를 지정된 소수 자릿수로 내림
 * @param {number} n - 내림할 숫자
 * @param {number} [pos=2] - 소수 자릿수, 기본값은 2
 * @returns {number} 내림된 결과를 반환
 * @example
 * floorFloat(3.145, 2); // 3.14
 */
export const floorFloat = (n: number, pos = 2): number => {
  if (n < 0) {
    return -Math.floor(-n * 10 ** pos) / 10 ** pos;
  }

  return Math.floor(n * 10 ** pos) / 10 ** pos;
};

/**
 * 주어진 숫자를 원화 형식으로 포맷
 * @param {number} n - 포맷할 숫자
 * @returns {string} 포맷이 적용된 원화 문자열을 반환, 숫자가 0이면 '무료'를 반환
 * @example
 * formatPriceToWon(1234); // "1,234원"
 */
export const formatPriceToWon = (n: number): string => {
  if (!n || n === 0) {
    return '무료';
  }

  return `${numeral(n).format('0,0')}원`;
};

/**
 * 주어진 숫자를 지정된 유효 자릿수에 따라 가장 가까운 값으로 반올림
 * @param {number} n - 반올림할 숫자
 * @param {number} [aboveTenPrecision=2] - 10 이상의 숫자의 경우 반올림할 유효 자릿수, 기본값은 2
 * @param {number} [belowTenMinPrecision=4] - 10 미만의 숫자의 경우 반올림할 최소 유효 자릿수, 기본값은 4
 * @returns {string} 반올림된 결과를 문자열로 반환
 * @example
 * setToNearestPrecision(1.234); // "1.23"
 */
export const setToNearestPrecision = (
  n: number,
  aboveTenPrecision = 2,
  belowTenMinPrecision = 4,
): string => {
  const log = -Math.floor(Math.log10(n));

  if (log < 0) {
    return n.toFixed(aboveTenPrecision);
  }

  if (log < belowTenMinPrecision) {
    return n.toFixed(belowTenMinPrecision);
  }

  return n.toFixed(log);
};
