export type CandleKind =
  | 'day'
  | 'min1'
  | 'min3'
  | 'min5'
  | 'min10'
  | 'min15'
  | 'min30'
  | 'min60'
  | 'min240';

export const candleKindToTextMap: Record<CandleKind, string> = {
  min1: '1분',
  min3: '3분',
  min5: '5분',
  min10: '10분',
  min15: '15분',
  min30: '30분',
  min60: '1시간',
  min240: '4시간',
  day: '일',
};

export type TradingViewCandleKind =
  | '1D'
  | '1'
  | '3'
  | '5'
  | '10'
  | '15'
  | '30'
  | '60'
  | '240';

export const tradingViewCandleToCandleKindMap: Record<
  TradingViewCandleKind,
  CandleKind
> = {
  '1D': 'day',
  '1': 'min1',
  '3': 'min3',
  '5': 'min5',
  '10': 'min10',
  '15': 'min15',
  '30': 'min30',
  '60': 'min60',
  '240': 'min240',
};
