import { cloneElement, PropsWithChildren, ReactElement } from 'react';

interface MultiProviderProps {
  providers: ReactElement[];
}

// MEMO: 렌더링 단에서의 context(provider) hell을 해결하기 위한 컴포넌트
const MultiProvider = ({
  providers,
  children,
}: PropsWithChildren<MultiProviderProps>) => {
  return (
    <>
      {providers.reduceRight((accProviders, provider) => {
        return cloneElement(provider, {}, accProviders);
      }, children)}
    </>
  );
};

export default MultiProvider;
