import { useState, useEffect, useRef } from 'react';

// ref: https://docs.coinone.co.kr/reference/public-websocket-ticker
export const useGetMarketCurrentPrices = (
  markets: string[],
): Record<string, number> => {
  const [prices, setPrices] = useState<Record<string, number>>({});
  const socketRef = useRef<WebSocket | null>(null);
  const shouldReconnectRef = useRef<boolean>(true);

  const wsUrl = 'wss://stream.coinone.co.kr';
  const reconnectDelay = 1000;

  useEffect(() => {
    const connectWebSocket = () => {
      socketRef.current = new WebSocket(wsUrl);

      socketRef.current.onopen = () => {
        markets.forEach((market) => {
          socketRef.current?.send(
            JSON.stringify({
              request_type: 'SUBSCRIBE',
              channel: 'TICKER',
              topic: {
                quote_currency: 'KRW',
                target_currency: market,
              },
            }),
          );
        });
      };

      socketRef.current.onmessage = (event: MessageEvent) => {
        const parsedData = JSON.parse(event.data).data;

        if (parsedData && parsedData.last && parsedData.target_currency) {
          setPrices((prevPrices) => ({
            ...prevPrices,
            [parsedData.target_currency]: parsedData.last,
          }));
        }
      };

      socketRef.current.onclose = (event: CloseEvent) => {
        if (shouldReconnectRef.current && event.code === 1006) {
          setTimeout(connectWebSocket, reconnectDelay);
        }
      };
    };

    connectWebSocket();

    return () => {
      shouldReconnectRef.current = false;
      socketRef.current?.close();
    };
  }, [markets]);

  return prices;
};
