import { PropsWithChildren } from 'react';

import Footer from 'components/Footer/Footer';
import GNBLayout from 'components/Layouts/GNBLayout';

import styles from './MainLayout.module.scss';

const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <GNBLayout>
      <div className={styles.root}>{children}</div>
      <Footer />
    </GNBLayout>
  );
};

export default MainLayout;
