import React, { PropsWithChildren } from 'react';

import clsx from 'clsx';

import styles from './index.module.scss';
import { ButtonProps } from './types';

export const Button = ({
  type = 'button',
  onClick = () => {},
  isClickable = true,
  size,
  theme,
  isFullWidth = false,
  extraClassNames = [],
  children,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!isClickable) {
      return;
    }

    e.preventDefault();
    onClick(e);
  };

  return (
    <button
      type={type}
      onClick={type === 'button' ? handleClick : undefined}
      className={clsx(
        styles.root,
        !isClickable && styles.disabled,
        styles[size],
        theme && styles[theme],
        isFullWidth && styles.fullWidth,
        extraClassNames.join(' '),
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
