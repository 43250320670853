import numeral from 'numeral';

/**
 * 주어진 문자열이 숫자인지 확인
 * @param {string} str - 확인할 문자열
 * @returns {boolean} 주어진 문자열이 숫자인 경우 true, 그렇지 않으면 false를 반환
 */
export const isNumeric = (str: string): boolean => {
  return !isNaN(parseFloat(str.replaceAll(',', '')));
};

/**
 * 숫자를 부호를 포함한 문자열 형식으로 변환
 * @param {number} number - 변환할 숫자
 * @param {boolean} [withComma=false] - 포맷된 문자열에 쉼표를 포함할지 여부, 기본값은 false
 * @returns {string} 포맷된 부호를 포함한 문자열
 */
export const toSignedString = (number: number, withComma = false): string => {
  return withComma
    ? numeral(number).format('+0,0')
    : numeral(number).format('+0.00');
};
