import clsx from 'clsx';
import { routes } from 'constants/';
import { useSafeRouter } from 'hooks';
import AssetIcon from 'images/icons/ic-asset.svg';
import BlogIcon from 'images/icons/ic-blog.svg';
import RobotIcon from 'images/icons/ic-robot.svg';
import SettingsIcon from 'images/icons/ic-setting.svg';
import StrategyIcon from 'images/icons/ic-strategy.svg';
import { useSession } from 'next-auth/react';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import SafeLink from 'components/SafeLink';

import styles from 'components/Navigation/MobileNavigation.module.scss';

const MobileNavigation = () => {
  const { router } = useSafeRouter();
  const { data: session } = useSession();

  if (!session) {
    return null;
  }

  const menuItems = [
    {
      name: '로봇',
      icon: <RobotIcon />,
      link: routes.tradingrobotRoute(session.username),
      pathname: '/user/[username]/tradingrobot',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_ROBOT);
      },
    },
    {
      name: '전략',
      icon: <StrategyIcon />,
      link: routes.marketplaceRoute,
      pathnames: ['/user/[username]/strategy', routes.marketplaceRoute],
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_STRTG);
      },
    },
    {
      name: '자산',
      icon: <AssetIcon />,
      link: routes.myAssetPageRoute(session.username),
      pathname: '/user/[username]/asset',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_ASSET);
      },
    },
    {
      name: '블로그',
      icon: <BlogIcon />,
      link: routes.blogRoute,
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_BT_BLOG);
      },
    },
    {
      name: '더보기',
      icon: <SettingsIcon />,
      link: routes.myPageRoute(session.username),
      pathname: '/user/[username]',
      onClick: () => {
        trackEvent(amplitudeEventNames.H_MENU_MYPAGE);
      },
    },
  ];

  return (
    <nav className={styles.root}>
      {menuItems.map(({ name, icon, link, pathname, pathnames, onClick }) => {
        return (
          <div
            className={clsx(
              styles.navItem,
              (link === router.pathname ||
                pathname === router.pathname ||
                pathnames?.includes(router.pathname)) &&
                styles.activeTab,
            )}
            key={name}
            onClick={onClick}
          >
            <SafeLink href={link}>
              {icon}
              <span>{name}</span>
            </SafeLink>
          </div>
        );
      })}
    </nav>
  );
};

export default MobileNavigation;
