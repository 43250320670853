import Swal from 'sweetalert2';

import colors from 'styles/constants/_colors.module.scss';

interface FireAlertProps {
  text: string;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  padding?: string;
  thenCallback?: () => void;
  catchCallback?: () => void;
  finallyCallback?: () => void;
  reverseButtons?: boolean;
}

/**
 * alert 창을 표시
 * @param {string} props.text - alert 창의 안내 문구
 * @param {string} [props.confirmButtonText='확인'] - 확인 버튼에 표시할 텍스트, 기본값은 '확인'
 * @param {string} [props.confirmButtonColor=colors.black] - 확인 버튼의 색상, 기본값은 black
 * @param {boolean} [props.showCancelButton=false] - 취소 버튼을 표시할지 여부, 기본값은 false
 * @param {string} [props.cancelButtonText='취소'] - 취소 버튼에 표시할 텍스트, 기본값은 '취소'
 * @param {string} [props.padding='12px'] - 기본값은 '12px'
 * @param {Function} [props.thenCallback] - 확인 버튼 클릭 시 실행할 콜백 함수
 * @param {Function} [props.catchCallback] - 오류가 발생했을 때 실행할 콜백 함수
 * @param {Function} [props.finallyCallback] - alert 창이 닫힌 후 항상 실행할 콜백 함수
 */
export const fireAlert = ({
  text,
  confirmButtonText = '확인',
  confirmButtonColor = colors.black,
  showCancelButton = false,
  cancelButtonText = '취소',
  padding = '12px',
  reverseButtons = false,
  thenCallback,
  catchCallback,
  finallyCallback,
}: FireAlertProps) => {
  Swal.fire({
    text,
    confirmButtonText,
    confirmButtonColor,
    cancelButtonText,
    padding,
    showCancelButton,
    reverseButtons,
  })
    .then((result) => {
      if (result.isConfirmed) {
        thenCallback && thenCallback();
      }
    })
    .catch(() => {
      catchCallback && catchCallback();
    })
    .finally(() => {
      finallyCallback && finallyCallback();
    });
};
