/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-key */
import type { AppProps } from 'next/app';
import Head from 'next/head';

import { useState, useEffect, PropsWithChildren } from 'react';

import { setUserId } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/nextjs';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { routes } from 'constants/';
import { useResponsive, useSafeRouter, useSyncUsername } from 'hooks';
import { noAuthPages } from 'middleware';
import { Session } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';

import { queryClient } from 'hooks/useQuery/base';

import { initAmplitude } from 'features/amplitude';

import ErrorBoundary from 'components/ErrorBoundary';
import MobileAppLayout from 'components/Layouts/MobileAppLayout';
import UnSupportedLayout from 'components/Layouts/UnSupportedLayout';
import MultiProvider from 'components/MultiProvider';

import 'styles/index.scss';
import SnackbarContextProvider from 'components/Snackbar';

const pageTitlesByPath: { [key: string]: string } = {
  '/': '트레이딩뱅크 | 퀀트 트레이딩 플랫폼',
  '/backtesting': '전략 생성 | 트레이딩뱅크',
  '/marketplace': '전략 마켓 | 트레이딩뱅크',
  '/blog': '블로그 | 트레이딩뱅크',
  '/backtesting/loading': '백테스팅 진행중 | 트레이딩뱅크',
  '/backtesting/result': '백테스팅 결과 | 트레이딩뱅크',
  '/user/[username]/strategy': '내 전략 | 트레이딩뱅크',
  '/user/[username]/tradingrobot': '내 로봇 | 트레이딩뱅크',
  '/signin': '로그인 | 트레이딩뱅크',
  '/signup': '회원가입 | 트레이딩뱅크',
  '/policy/terms': '이용약관 | 트레이딩뱅크',
  '/policy/privacy': '개인정보정책 | 트레이딩뱅크',
};

const exceptionPaths = ['/callback'];
const mobileSupportedPaths = [
  routes.rootRoute,
  routes.signInRoute,
  routes.signUpRoute,
  routes.homeRoute,
  routes.termsRoute,
  routes.privacyRoute,
  routes.blogRoute,
  '/user/[username]',
];
const responsiveSupportedPaths = [...exceptionPaths, ...mobileSupportedPaths];

const AuthPageRedirectLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { router, safePush } = useSafeRouter();
  const { status, data: session } = useSession();

  useEffect(() => {
    if (session) {
      Sentry.setUser({
        username: session.username,
        email: session.user?.email || 'email null or undefined',
      });

      setUserId(session.user?.email || 'email null or undefined');
    }
  }, [session]);

  useSyncUsername();

  if (noAuthPages.includes(router.pathname)) {
    return <>{children}</>;
  }

  if (status === 'unauthenticated') {
    safePush(routes.signInRoute);
    return null;
  }

  if (status === 'authenticated') {
    return <>{children}</>;
  }

  return null;
};

const ResponsiveLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { router } = useSafeRouter();
  const { pathname } = router;

  return (
    <>
      {responsiveSupportedPaths.some((path) => pathname.includes(path)) ? (
        children
      ) : (
        <UnSupportedLayout>{children}</UnSupportedLayout>
      )}
    </>
  );
};

initAmplitude();

const App = ({
  Component,
  pageProps,
}: AppProps<{
  session: Session;
}>) => {
  const [isHydrated, setIsHydrated] = useState(false);
  const { router } = useSafeRouter();
  const { pathname } = router;
  const { isMobile } = useResponsive();

  useEffect(() => {
    // console.warn 재정의
    const originalWarn = console.warn;
    console.warn = (...args) => {
      if (
        args[0] &&
        typeof args[0] === 'string' &&
        args[0].includes('[NACHOCODE]')
      ) {
        // nachocode, `[NACHOCODE]`와 관련된 경고는 무시
        return;
      }
      originalWarn.apply(console, args);
    };
  }, []);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    const initializeNachocode = () => {
      if (!isMobile) {
        return;
      }

      if (typeof window === 'undefined' || !window.Nachocode) {
        Sentry.captureException(new Error('Nachocode SDK is not loaded.'));
        return;
      }

      if (Nachocode.env.isInitialized()) {
        return;
      }

      try {
        Nachocode.init('CtTKrAiTaTdnCavJxIJl', { logger: true });

        Sentry.captureMessage('success to init nacho', {
          level: 'info',
          extra: {
            isApp: Nachocode.env.isApp(),
            version: Nachocode.app.getCurrentAppVersion(),
          },
        });
      } catch (error) {
        Sentry.captureMessage('fail to init nacho', {
          level: 'error',
          extra: {
            isApp: Nachocode.env.isApp(),
            version: Nachocode.app.getCurrentAppVersion(),
            error,
          },
        });
      }
    };

    initializeNachocode();
  }, [isMobile]);

  if (!isHydrated) {
    return (
      <Head>
        <title>{pageTitlesByPath[pathname] || '트레이딩뱅크'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
    );
  }

  return (
    <>
      <Head>
        <title>{pageTitlesByPath[pathname] || '트레이딩뱅크'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
      <ErrorBoundary isMobile={isMobile}>
        <MultiProvider
          providers={[
            <SessionProvider session={pageProps.session} children={null} />,
            <QueryClientProvider client={queryClient} />,
            <AuthPageRedirectLayout />,
            <SnackbarContextProvider />,
            <ResponsiveLayout />,
            <MobileAppLayout />,
          ]}
        >
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />
        </MultiProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
