import pino from 'pino';

/**
 * 브라우저에서 사용할 수 있는 로거
 * @param {object} options - 로거 옵션
 * @param {boolean} [options.browser.asObject=true] - 브라우저에서 객체 형식으로 로그를 출력할지 여부입니다.
 * @param {Function} [options.timestamp] - 타임스탬프 함수입니다.
 * @returns {Logger} Pino 로거 객체를 반환
 */
export const logger = pino({
  browser: { asObject: true },
  timestamp: () => `,"time":"${new Date(Date.now()).toISOString()}"`,
});
