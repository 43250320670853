/**
 * 수익률을 계산
 * @param {number} buyPrice - 매수 가격
 * @param {number} currentPrice - 현재 가격
 * @returns {number} 수익률을 퍼센트로 반환
 */
export const calculateRor = (
  buyPrice: number,
  currentPrice: number,
): number => {
  return (currentPrice / buyPrice) * 100 - 100;
};
