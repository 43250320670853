import { routes } from 'constants/';

import { amplitudeEventNames } from 'features/amplitude';

export interface MenuItem {
  name: string;
  to: string | ((param: string) => string);
  pathPattern: string;
  amplitudeEventName?: string;
}

export const loginMenuItems: MenuItem[] = [
  {
    name: '이용 약관',
    to: routes.termsRoute,
    pathPattern: '/policy/terms/',
  },
  {
    name: '개인정보 처리방침',
    to: routes.privacyRoute,
    pathPattern: '/policy/privacy/',
  },
];

export const footerItem = [
  {
    title: '대표',
    info: '장기벽',
  },
  {
    title: '사업자등록번호',
    info: '473-87-02207',
  },
  {
    title: '주소',
    info: '서울특별시 마포구 마포대로 122 프론트원 14층',
  },
  {
    title: '문의',
    info: 'tradingbank@timepercentcorp.com',
  },
];

export const menuNames = {
  STRATEGY: '전략',
  TRADINGROBOT: '로봇',
  MARKETPLACE: '마켓',
  MYASSET: '자산',
  BLOG: 'Blog ✨',
  COMPANY: '회사 소개',
};

export const landingMenuItems: MenuItem[] = [
  {
    name: menuNames.BLOG,
    to: routes.blogRoute,
    pathPattern: '/blog',
  },
  {
    name: menuNames.COMPANY,
    to: routes.companyRoute,
    pathPattern: '/timepercentcorp',
  },
];
export const menuItems: MenuItem[] = [
  {
    name: menuNames.STRATEGY,
    to: routes.myStrategyRoute,
    pathPattern: '/user/[username]/strategy',
    amplitudeEventName: amplitudeEventNames.H_MENU_STRTG,
  },
  {
    name: menuNames.TRADINGROBOT,
    to: routes.tradingrobotRoute,
    pathPattern: '/user/[username]/tradingrobot',
    amplitudeEventName: amplitudeEventNames.H_MENU_ROBOT,
  },
  {
    name: menuNames.MARKETPLACE,
    to: routes.marketplaceRoute,
    pathPattern: '/marketplace',
    amplitudeEventName: amplitudeEventNames.H_MENU_BT_MARKETPLACE,
  },
  {
    name: menuNames.MYASSET,
    to: routes.myAssetRoute,
    pathPattern: '/user/[username]/asset',
    amplitudeEventName: amplitudeEventNames.H_MENU_ASSET,
  },
  {
    name: menuNames.BLOG,
    to: routes.blogRoute,
    pathPattern: '/blog',
    amplitudeEventName: amplitudeEventNames.H_MENU_BT_BLOG,
  },
];

export const policy = [
  {
    title: 'Terms',
    link: routes.termsRoute,
  },
  {
    title: 'Privacy Policy',
    link: routes.privacyRoute,
  },
  {
    title: 'Contact Us',
    link: routes.companyRoute,
  },
];
