import { PropsWithChildren, useEffect } from 'react';

import { routes } from 'constants/';
import { useResponsive, useSafeRouter } from 'hooks';
import { useSession } from 'next-auth/react';

import styles from './index.module.scss';

const UnSupportedLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isUnSupported } = useResponsive();
  const { safePush } = useSafeRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (!isUnSupported) {
      return;
    }

    if (status === 'authenticated') {
      safePush(routes.myStrategyRoute(session.username));
      return;
    }

    safePush('/');
  }, [isUnSupported, safePush, session, status]);

  if (isUnSupported) {
    return (
      <div className={styles.unsupport}>
        <div>현재 이 페이지는</div>
        <div>PC 에서만 이용 가능해요 :(</div>
      </div>
    );
  }

  return <>{children}</>;
};

export default UnSupportedLayout;
