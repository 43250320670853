import Link from 'next/link';

import { PropsWithChildren, useState } from 'react';

import styles from './index.module.scss';

interface SafeLinkProps extends PropsWithChildren {
  href: string;
  delay?: number;
  openInNewTab?: boolean;
}

const SafeLink = ({
  href,
  delay = 500,
  openInNewTab = false,
  children,
}: SafeLinkProps) => {
  const [isNavigating, setIsNavigating] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isNavigating) {
      e.preventDefault();
      return;
    }
    setIsNavigating(true);
    setTimeout(() => setIsNavigating(false), delay);
  };

  return (
    <Link href={href} passHref>
      <a
        className={styles.root}
        onClick={handleClick}
        target={openInNewTab ? '_blank' : '_self'}
        rel={openInNewTab ? 'noreferrer' : undefined}
      >
        {children}
      </a>
    </Link>
  );
};

export default SafeLink;
