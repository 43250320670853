/**
 * 주어진 텍스트 내용을 파일로 내보내기
 * @param {string} filename - 내보낼 파일의 이름
 * @param {string} content - 내보낼 텍스트 내용
 * @example
 * exportTextToFile('example.txt', 'This is the content of the file.');
 */
export const exportTextToFile = (filename: string, content: string) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(content),
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
