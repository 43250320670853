import {
  RelationalOperatorKind,
  relationalOperatorKinds,
  Formula,
  MarketIndicatorInfo,
  TokenValue,
} from 'constants/';
import { BacktestingSettingState } from 'store/backtesting';

const NO_RELATIONAL_OPERATOR = '부등식 없음';
const NO_INDICATOR = '지표 없음';
const NO_ALTERNATING = '(지표, 수) 혹은 (부등식, 연산자)가 연속됨';
const NO_START_END_WITH_INDICATOR_OR_NUMBER =
  '시작과 끝에는 지표, 수 외에 다른 게 있음';
export const NO_SAME_CANDLES = '모든 포뮬라의 기준봉이 동일하지 않다.';

const 지표인가 = (token: TokenValue) =>
  token &&
  typeof token === 'object' &&
  'indicator' in (token as MarketIndicatorInfo);

const 수인가 = (token: TokenValue) => {
  if (typeof token === 'number') {
    return true;
  }

  if (typeof token !== 'string') {
    return false;
  }

  return !isNaN(Number(token.replaceAll(',', '')));
};

const 지표_혹은_수인가 = (token: TokenValue) =>
  지표인가(token) || 수인가(token);

const 부등식_포함하는가 = (tokens: TokenValue[]) =>
  tokens.some((token) =>
    relationalOperatorKinds.includes(token as RelationalOperatorKind),
  );

const 지표_포함하는가 = (tokens: TokenValue[]) =>
  tokens.some((token) => 지표인가(token));

const 지표와수_혹은_부등식연산자_불연속하게_나오는가 = (arr: boolean[]) =>
  arr.every((item, index) => item !== arr[index + 1]);

const 시작과끝_지표_혹은_수만_오는가 = (tokens: TokenValue[]) =>
  지표_혹은_수인가(tokens[0]) && 지표_혹은_수인가(tokens[tokens.length - 1]);

export const validateFormulas = (
  formulas: Formula[],
  setInvalidStrategyType?: React.Dispatch<React.SetStateAction<string>>,
) => {
  const validations = [
    {
      isValid: formulas.every(({ tokens }) => 부등식_포함하는가(tokens)),
      callback: () => {
        setInvalidStrategyType?.(NO_RELATIONAL_OPERATOR);
      },
    },
    {
      isValid: formulas.every(({ tokens }) => 지표_포함하는가(tokens)),
      callback: () => {
        setInvalidStrategyType?.(NO_INDICATOR);
      },
    },
    {
      isValid: formulas.every(({ tokens }) =>
        지표와수_혹은_부등식연산자_불연속하게_나오는가(
          tokens.map(지표_혹은_수인가),
        ),
      ),
      callback: () => {
        setInvalidStrategyType?.(NO_ALTERNATING);
      },
    },
    {
      isValid: formulas.every(({ tokens }) =>
        시작과끝_지표_혹은_수만_오는가(tokens),
      ),
      callback: () => {
        setInvalidStrategyType?.(NO_START_END_WITH_INDICATOR_OR_NUMBER);
      },
    },
  ];

  // MEMO: TB-4705, 기준봉이 다를 때 프리뷰만 불가능하고 백테스팅은 가능해야 함
  if (setInvalidStrategyType) {
    validations.push({
      isValid: formulas.every(({ kind }) => kind === formulas[0]?.kind),
      callback: () => {
        setInvalidStrategyType(NO_SAME_CANDLES);
      },
    });
  }

  if (
    validations.some(({ isValid, callback }) => {
      if (!isValid) {
        callback();
        return true;
      }

      return false;
    })
  ) {
    return false;
  }

  setInvalidStrategyType?.('');
  return true;
};

export const getStrategySettingValidation = (
  backtestingSetting: Pick<
    BacktestingSettingState,
    | 'principal'
    | 'startAndEndDate'
    | 'market'
    | 'conditionExpression'
    | 'buyFormulas'
    | 'sellFormulas'
    | 'sellConfig'
  >,
) => {
  const {
    principal,
    startAndEndDate,
    market,
    conditionExpression,
    buyFormulas,
    sellFormulas,
    sellConfig,
  } = backtestingSetting;

  const isBasicSettingValid =
    Boolean(principal) && startAndEndDate.length === 2;

  const isMarketSettingValid = Boolean(market);

  const isBuyStrategySettingValid =
    conditionExpression.buyStrategy.length > 0 &&
    validateFormulas(buyFormulas) &&
    buyFormulas.length > 0 &&
    buyFormulas[0].tokens.length > 0;

  const isSellStrategySettingValid =
    (sellFormulas.length === 0 || validateFormulas(sellFormulas)) &&
    Boolean(sellConfig.market_max_holding_minutes) &&
    (Number(sellConfig.profit_cut) > 0 || sellConfig.profit_cut === null) &&
    (Number(sellConfig.loss_cut) > 0 || sellConfig.loss_cut === null);

  return {
    isBasicSettingValid,
    isMarketSettingValid,
    isBuyStrategySettingValid,
    isSellStrategySettingValid,
  };
};
