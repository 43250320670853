import { Dispatch, SetStateAction, useState } from 'react';

interface OpenState {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

interface ToggleEffectProps {
  initialState?: boolean;
  checkNextIsOpen?: (state: OpenState) => boolean;
}

type ToggleEffect = ({
  initialState,
  checkNextIsOpen,
}: ToggleEffectProps) => [boolean, () => void];

export const useToggle: ToggleEffect = ({
  checkNextIsOpen,
  initialState = false,
}): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState);

  const callback = checkNextIsOpen
    ? () => {
        const nextIsOpen = checkNextIsOpen({ isOpen, setIsOpen });
        setIsOpen(nextIsOpen);
      }
    : () => {
        const nextIsOpen = !isOpen;
        setIsOpen(nextIsOpen);
      };

  return [isOpen, callback];
};
