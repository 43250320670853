import { PropsWithChildren } from 'react';

import styles from './index.module.scss';

interface IconButtonWithTooltipProps {
  onClick?: () => void;
  tooltipMessage?: string;
}

const IconButtonWithTooltip = ({
  onClick,
  tooltipMessage,
  children,
}: PropsWithChildren<IconButtonWithTooltipProps>) => {
  return (
    <div className={styles.root}>
      <button onClick={onClick} className={styles.wrap}>
        {children}
      </button>
      {tooltipMessage && (
        <div className={styles.messageBox}>
          <span>{tooltipMessage}</span>
        </div>
      )}
    </div>
  );
};

export default IconButtonWithTooltip;
