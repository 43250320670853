import { useEffect, useState } from 'react';

import { usePrevious } from 'react-use';

export const useHighlightChange = (value: number | string, duration = 500) => {
  // MEMO: hightLightChange는 _common.scss 에서 정의된 클래스명
  const [animationCssClassname, setAnimationCssClassname] = useState<
    'highlightChange' | null
  >(null);
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue !== value) {
      setAnimationCssClassname('highlightChange');
    }

    const timer = setTimeout(() => {
      setAnimationCssClassname(null);
    }, duration);

    return () => clearTimeout(timer);
  }, [value, prevValue, duration]);

  return animationCssClassname;
};
