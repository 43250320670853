type StageKind = 'dev' | 'test' | 'prod' | 'local';
export type FlagKind =
  | 'FLAG_FORMULA_FUNCTIONPICKER'
  | 'FLAG_MARKET_FILTER'
  | 'FLAG_HEADER_MENU'
  | 'FLAG_MULTIPLE_MARKET'
  | 'FLAG_MARKETPLACE';
type FlagEnvSettings = {
  [key in FlagKind]: 0 | 1;
};

interface PublicAppEnvSettings extends FlagEnvSettings {
  // Define public env variables in either '.env.dev', '.env.test', or '.env.prod'.
  // Those keys should be prefixed with 'REACT_APP_'.
  // The following keys should be visible to client-side:
  HOSTNAME: string;
  STAGE: StageKind;
  ACCOUNT_SERVICE_HOSTNAME: string;
  CHART_SERVICE_HOSTNAME: string;
  CHANNEL_PLUGIN_KEY: string;
  AMPLITUDE_KEY: string;
  WDS_SOCKET_PORT: number | string;
  // firebase
  FIREBASE_APP_ID: string;
  FIREBASE_API_KEY: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_VAPID_KEY: string;
  FIREBASE_MEASUREMENT_ID: string;
}

interface PrivateAppEnvSettings {
  // The following keys are NOT visible to client-side, and are secrets:
  // (DO NOT PUT 'NEXT_PUBLIC_' PREFIX. They are injected from the server.)
  NEXT_SECRET: string;
  NAVER_ID: string;
  NAVER_SECRET: string;
  GOOGLE_ID: string;
  GOOGLE_SECRET: string;
  COINONE_ID: string;
  COINONE_SECRET: string;
  APPLE_ID: string;
  APPLE_SECRET: string;
  NEXT_AUTH_JWT_MAX_AGE: number;
  NEXT_AUTH_SESSION_MAX_AGE: number;
  COINONE_SOCKS5_HOST: string;
  COINONE_SOCKS5_KEY_PATH: string;
}

export type AppEnvSettings = PublicAppEnvSettings & PrivateAppEnvSettings;

const message = (key: string) => {
  return `'${key}' is not set`;
};

/**
 * Define variables visible to both client-side and server-side.
 * Note that some values such as API urls might be different from each other.
 *
 * Server-side env variables, which are accessible via `process.env`, can be configured at runtime,
 * whereas client-side env variables must be pre-configured and distributed with those values.
 */
const createPublicAppEnvSettings = (): PublicAppEnvSettings => {
  // server-side (DO NOT USE DYNAMIC LOOKUP)
  if (typeof window === 'undefined') {
    return {
      HOSTNAME: process.env.HOSTNAME ?? message('HOSTNAME'),
      STAGE: (process.env.STAGE as StageKind) ?? message('STAGE'),
      ACCOUNT_SERVICE_HOSTNAME:
        process.env.ACCOUNT_SERVICE_HOSTNAME ??
        message('ACCOUNT_SERVICE_HOSTNAME'),
      CHART_SERVICE_HOSTNAME:
        process.env.CHART_SERVICE_HOSTNAME ?? message('CHART_SERVICE_HOSTNAME'),
      CHANNEL_PLUGIN_KEY:
        process.env.CHANNEL_PLUGIN_KEY ?? message('CHANNEL_PLUGIN_KEY'),
      AMPLITUDE_KEY: process.env.AMPLITUDE_KEY ?? message('AMPLITUDE_KEY'),
      FLAG_FORMULA_FUNCTIONPICKER: Number(
        process.env.FLAG_FORMULA_FUNCTIONPICKER,
      ) as 0 | 1,
      FLAG_MARKET_FILTER: Number(process.env.FLAG_MARKET_FILTER) as 0 | 1,
      FLAG_HEADER_MENU: Number(process.env.FLAG_HEADER_MENU) as 0 | 1,
      FLAG_MULTIPLE_MARKET: Number(process.env.FLAG_MULTIPLE_MARKET) as 0 | 1,
      FLAG_MARKETPLACE: Number(process.env.FLAG_MARKETPLACE) as 0 | 1,
      WDS_SOCKET_PORT:
        process.env.WDS_SOCKET_PORT ?? message('WDS_SOCKET_PORT'),
      FIREBASE_APP_ID:
        process.env.FIREBASE_APP_ID ?? message('FIREBASE_APP_ID'),
      FIREBASE_API_KEY:
        process.env.FIREBASE_API_KEY ?? message('FIREBASE_API_KEY'),
      FIREBASE_PROJECT_ID:
        process.env.FIREBASE_PROJECT_ID ?? message('FIREBASE_PROJECT_ID'),
      FIREBASE_STORAGE_BUCKET:
        process.env.FIREBASE_STORAGE_BUCKET ??
        message('FIREBASE_STORAGE_BUCKET'),
      FIREBASE_AUTH_DOMAIN:
        process.env.FIREBASE_AUTH_DOMAIN ?? message('FIREBASE_AUTH_DOMAIN'),
      FIREBASE_MESSAGING_SENDER_ID:
        process.env.FIREBASE_MESSAGING_SENDER_ID ??
        message('FIREBASE_MESSAGING_SENDER_ID'),
      FIREBASE_VAPID_KEY:
        process.env.FIREBASE_VAPID_KEY ?? message('FIREBASE_VAPID_KEY'),
      FIREBASE_MEASUREMENT_ID:
        process.env.FIREBASE_MEASUREMENT_ID ??
        message('FIREBASE_MEASUREMENT_ID'),
    };
  }

  // client-side
  const publicEnv = window.__ENV;
  return {
    HOSTNAME: publicEnv['REACT_APP_HOSTNAME'] ?? message('HOSTNAME'),
    STAGE: (publicEnv['REACT_APP_STAGE'] as StageKind) ?? message('STAGE'),
    ACCOUNT_SERVICE_HOSTNAME:
      publicEnv['REACT_APP_ACCOUNT_SERVICE_HOSTNAME'] ??
      message('ACCOUNT_SERVICE_HOSTNAME'),
    CHART_SERVICE_HOSTNAME:
      publicEnv['REACT_APP_CHART_SERVICE_HOSTNAME'] ??
      message('CHART_SERVICE_HOSTNAME'),
    CHANNEL_PLUGIN_KEY:
      publicEnv['REACT_APP_CHANNEL_PLUGIN_KEY'] ??
      message('CHANNEL_PLUGIN_KEY'),
    AMPLITUDE_KEY:
      publicEnv['REACT_APP_AMPLITUDE_KEY'] ?? message('AMPLITUDE_KEY'),
    FLAG_FORMULA_FUNCTIONPICKER: Number(
      publicEnv['REACT_APP_FLAG_FORMULA_FUNCTIONPICKER'],
    ) as 0 | 1,
    FLAG_MARKET_FILTER: Number(publicEnv['REACT_APP_FLAG_MARKET_FILTER']) as
      | 0
      | 1,

    FLAG_HEADER_MENU: Number(publicEnv['REACT_APP_FLAG_HEADER_MENU']) as 0 | 1,
    FLAG_MULTIPLE_MARKET: Number(
      publicEnv['REACT_APP_FLAG_MULTIPLE_MARKET'],
    ) as 0 | 1,
    FLAG_MARKETPLACE: Number(publicEnv['REACT_APP_FLAG_MARKETPLACE']) as 0 | 1,
    WDS_SOCKET_PORT:
      publicEnv['REACT_APP_WDS_SOCKET_PORT'] ?? message('WDS_SOCKET_PORT'),
    FIREBASE_APP_ID:
      publicEnv['REACT_APP_FIREBASE_APP_ID'] ??
      message('REACT_APP_FIREBASE_APP_ID'),
    FIREBASE_API_KEY:
      publicEnv['REACT_APP_FIREBASE_API_KEY'] ??
      message('REACT_APP_FIREBASE_API_KEY'),
    FIREBASE_PROJECT_ID:
      publicEnv['REACT_APP_FIREBASE_PROJECT_ID'] ??
      message('REACT_APP_FIREBASE_PROJECT_ID'),
    FIREBASE_STORAGE_BUCKET:
      publicEnv['REACT_APP_FIREBASE_STORAGE_BUCKET'] ??
      message('REACT_APP_FIREBASE_STORAGE_BUCKET'),
    FIREBASE_AUTH_DOMAIN:
      publicEnv['REACT_APP_FIREBASE_AUTH_DOMAIN'] ??
      message('REACT_APP_FIREBASE_AUTH_DOMAIN'),
    FIREBASE_MESSAGING_SENDER_ID:
      publicEnv['REACT_APP_FIREBASE_MESSAGING_SENDER_ID'] ??
      message('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
    FIREBASE_VAPID_KEY:
      publicEnv['REACT_APP_FIREBASE_VAPID_KEY'] ??
      message('REACT_APP_FIREBASE_VAPID_KEY'),
    FIREBASE_MEASUREMENT_ID:
      publicEnv['REACT_APP_FIREBASE_MEASUREMENT_ID'] ??
      message('REACT_APP_FIREBASE_MEASUREMENT_ID'),
  };
};

/**
 * Define variables visible to server-side only.
 * Beware that dynamic lookup will NOT be inlined.
 *
 * const varName = 'NEXT_PUBLIC_ANALYTICS_ID'
 * setupAnalyticsService(process.env[varName])
 *
 * const env = process.env
 * setupAnalyticsService(env.NEXT_PUBLIC_ANALYTICS_ID)
 */
const createPrivateAppEnvSettings = (): PrivateAppEnvSettings => {
  const jwtMaxAge = process.env.NEXT_AUTH_JWT_MAX_AGE ?? '86400'; // 1 day
  const sessionMaxAge = process.env.NEXT_AUTH_SESSION_MAX_AGE ?? '2592000'; // 30 days

  return {
    NEXT_SECRET: process.env.UNIQUE_STATE ?? message('UNIQUE_STATE'),
    GOOGLE_SECRET:
      process.env.GOOGLE_CLIENT_SECRET ?? message('GOOGLE_CLIENT_SECRET'),
    GOOGLE_ID: process.env.GOOGLE_CLIENT_ID ?? message('GOOGLE_CLIENT_ID'),
    NAVER_ID: process.env.NAVER_CLIENT_ID ?? message('NAVER_CLIENT_ID'),
    NAVER_SECRET:
      process.env.NAVER_CLIENT_SECRET ?? message('NAVER_CLIENT_SECRET'),
    APPLE_ID: process.env.APPLE_ID ?? message('APPLE_ID'),
    APPLE_SECRET: process.env.APPLE_SECRET ?? message('APPLE_SECRET'),
    COINONE_ID: process.env.COINONE_CLIENT_ID ?? message('COINONE_CLIENT_ID'),
    COINONE_SECRET:
      process.env.COINONE_CLIENT_SECRET ?? message('COINONE_CLIENT_SECRET'),
    NEXT_AUTH_JWT_MAX_AGE: parseInt(jwtMaxAge),
    NEXT_AUTH_SESSION_MAX_AGE: parseInt(sessionMaxAge),
    COINONE_SOCKS5_HOST:
      process.env.COINONE_SOCKS5_HOST ?? message('COINONE_SOCKS5_HOST'),
    COINONE_SOCKS5_KEY_PATH:
      process.env.COINONE_SOCKS5_KEY_PATH ?? message('COINONE_SOCKS5_KEY_PATH'),
  };
};

export const env = {
  ...createPublicAppEnvSettings(),
  ...createPrivateAppEnvSettings(),
};
